import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { SocialCardInterface } from './store';
import ChevronDown from '~/common/components/ui-elements/svg-icon/svgs/ChevronDown';
import ChevronUp from '~/common/components/ui-elements/svg-icon/svgs/ChevronUp';

interface Props {
  contentId: string;
  socialCard: SocialCardInterface[];
}
export const SocialCard = React.memo<Props>(({ contentId, socialCard }) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [viewLimit, setViewLimit] = useState<number>(5);
  const [contents, setContents] = useState<SocialCardInterface[]>([]);
  useEffect(() => {
    const socialCards: SocialCardInterface[] = socialCard.map((item) => ({
      image: item.image,
      date: item.date,
      userName: item.userName,
      altText: item.altText,
      modelName: item.modelName,
      header: item.header,
    }));

    setContents(socialCards);
  }, [socialCard]);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;

      if (width <= 425) {
        setViewLimit(4);
      } else if (width <= 1080) {
        setViewLimit(3);
      } else if (contents.length <= 4) {
        setViewLimit(contents.length);
      } else {
        setViewLimit(5);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contents]);

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  // Insert List
  const RenderList = ({
    content,
  }: {
    key: any;
    content: SocialCardInterface;
  }) => (
    <div className={styles.post}>
      <div className={styles.imageHolder}>
        <img src={content.image} alt={content.altText} />
      </div>
      <div className={styles.content}>
        <span className={styles.instagramUsername}>{content.userName}</span>
        <span className={styles.timeShared}>{content.date}</span>
      </div>
    </div>
  );
  return (
    <div id={contentId} className={styles.social_container}>
      <h2 className={styles.headline}>{contents[0]?.header}</h2>
      <div className={styles.posts}>
        {viewMore
          ? contents?.map((content, index) => (
              <RenderList key={index} content={content} />
            ))
          : contents
              ?.slice(0, viewLimit)
              ?.map((content, index) => (
                <RenderList key={index} content={content} />
              ))}
      </div>
      <div className={styles.footer}>
        <a className={styles.viewMore} onClick={handleViewMore}>
          {viewMore ? (
            <>
              View Less
              <ChevronUp color="#1637A0" />
            </>
          ) : (
            <>
              View More
              <ChevronDown color="#1637A0" />
            </>
          )}
        </a>
      </div>
    </div>
  );
});

export default SocialCard;
